import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }
const _hoisted_2 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }
const _hoisted_3 = { class: "flex flex-row justify-between items-center pt-8 pb-4" }
const _hoisted_4 = { class: "w-1/6 whitespace-nowrap" }
const _hoisted_5 = { class: "overflow-auto h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_CustomDropdown, {
          selectedItem: _ctx.params.status,
          "onUpdate:selectedItem": _cache[1] || (_cache[1] = ($event: any) => (_ctx.params.status = $event)),
          options: _ctx.statusData,
          placeholder: "Status",
          onSelect: _ctx.onSelectStatus
        }, null, 8, ["selectedItem", "options", "onSelect"])
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_TableV2, {
        loading: _ctx.listData.loading,
        borderTop: false,
        borderBottom: false,
        columns: _ctx.columns,
        data: _ctx.listData.data,
        isEmpty: !_ctx.listData.data.length && !_ctx.params.status.value,
        emptyHeaderMessage: "Tidak Ada Konfigurasi Automasi Indikasi DEX Palsu",
        emptyMessage: "Mulai buat Konfigurasi Automasi Indikasi DEX Palsu dan cek daftarnya disini",
        class: "my-2",
        onClick: _ctx.onClickRow,
        isDetailAble: _ctx.isDetailAble,
        pagination: _ctx.listData.pagination,
        "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_ctx.listData.pagination = $event)),
        onRequest: _ctx.fetchList,
        onTryAgain: _ctx.fetchList,
        paginationStyle: "v3"
      }, null, 8, ["loading", "columns", "data", "isEmpty", "onClick", "isDetailAble", "pagination", "onRequest", "onTryAgain"])
    ])
  ]))
}