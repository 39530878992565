
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import statusListData from "@/app/infrastructures/misc/common-library/StatusListData";
import { Vue } from "vue-class-component";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ConfigFakeDexIndicationController } from "@/app/ui/controllers/ConfigFakeDexIndicationController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { ConfigFakeDexIndicationDetail } from "@/domain/entities/ConfigFakeDexIndication";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { FlagsPermissionConfigFakeDexIndication } from "@/feature-flags/flags-permission-config-fake-dex-indication";

export default class List extends Vue {
  mounted() {
    this.fetchList();
  }
  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  params = {
    status: new OptionsClass()
  };

  get statusData(): OptionsClass[] {
    return statusListData(["active", "inactive"]);
  }
  onSelectStatus(): void {
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  listData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchList() {
    this.listData.loading = true;
    try {
      this.listData = await ConfigFakeDexIndicationController.getList(
        new ApiRequestList({
          page: this.listData.pagination.page,
          limit: this.listData.pagination.limit,
          status: this.params.status.value
        })
      );
    } catch (err) {
      this.listData.message = parsingErrorResponse(err).type;
    } finally {
      this.listData.loading = false;
    }
  }

  columns = [
    {
      name: "ID",
      styleHead: "w-3/20 text-left",
      render: (item: ConfigFakeDexIndicationDetail) =>
        `<span class='text-left text-black-lp-300 flex'>${item.dfdcId}</span>`
    },
    {
      name: "Nama Konfigurasi",
      styleHead: "w-3/20 text-left",
      render: (item: ConfigFakeDexIndicationDetail) =>
        `<span class='text-left text-black-lp-300 flex'>${item.dfdcName}</span>`
    },
    {
      name: "Deskripsi Konfigurasi",
      styleHead: "w-1/5 text-left",
      render: (item: ConfigFakeDexIndicationDetail) =>
        `<span class='text-left text-black-lp-300 flex'>${item.dfdcDescription}</span>`
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-1/5 text-left",
      render: (item: ConfigFakeDexIndicationDetail) =>
        `<span class='text-black-lp-300 flex'>${formatDate(
          item.dfdcCreatedAt
        )}</span>
        <div class="capitalize text-gray-lp-500">${item.dfdcCreatedBy}</div>`
    },
    {
      name: "Tanggal Diperbarui",
      styleHead: "w-1/5 text-left",
      render: (item: ConfigFakeDexIndicationDetail) =>
        `<span class='text-black-lp-300 flex'>${formatDate(
          item.dfdcUpdatedAt
        )}</span>
        <div class="capitalize text-gray-lp-500">${item.dfdcUpdatedBy}</div>`
    },
    {
      name: "Status",
      styleHead: "w-2/20 text-left text-black-lp-300",
      render: (item: ConfigFakeDexIndicationDetail) =>
        ChipsStyles({ status: item.dfdcStatus })
    }
  ];

  onClickRow(item: ConfigFakeDexIndicationDetail) {
    this.$router.push(`${this.$route.path}/${item.dfdcId}`);
  }

  get isDetailAble() {
    return FlagsPermissionConfigFakeDexIndication.permission_config_fake_dex_indication_enable.isEnabled();
  }
}
