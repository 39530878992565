import { ConfigFakeDexIndicationPresenter } from "@/app/ui/presenters/ConfigFakeDexIndicationPresenter";
import { ConfigFakeDexIndicationMapper } from "@/data/persistences/mappers/ConfigFakeDexIndicationMapper";
import { container } from "tsyringe";
import { ConfigFakeDexIndicationEndpoint } from "../../endpoints/horde/ConfigFakeDexIndicationEndpoint";
import { ConfigFakeDexIndicationApiRepository } from "../../repositories/api/ConfigFakeDexIndicationApiRepository";
import ApiService from "../../services/ApiService";

export class ConfigFakeDexIndicationComponent {
  public static init() {
    container.register<ConfigFakeDexIndicationApiRepository>(
      ConfigFakeDexIndicationApiRepository,
      {
        useFactory: d => {
          return new ConfigFakeDexIndicationApiRepository(
            d.resolve(ApiService),
            d.resolve(ConfigFakeDexIndicationMapper),
            d.resolve(ConfigFakeDexIndicationEndpoint)
          );
        }
      }
    );
    container.register<ConfigFakeDexIndicationMapper>(
      ConfigFakeDexIndicationMapper,
      {
        useClass: ConfigFakeDexIndicationMapper
      }
    );
    container.register<ConfigFakeDexIndicationPresenter>(
      ConfigFakeDexIndicationPresenter,
      {
        useFactory: d => {
          return new ConfigFakeDexIndicationPresenter(
            d.resolve(ConfigFakeDexIndicationApiRepository)
          );
        }
      }
    );
  }
}
