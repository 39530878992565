/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { ConfigFakeDexIndicationPresenter } from "../presenters/ConfigFakeDexIndicationPresenter";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ConfigFakeDexIndicationComponent } from "@/app/infrastructures/dependencies/modules/ConfigFakeDexIndicationComponent";
import { EditConfigFakeDexIndicationRequest } from "@/data/payload/api/ConfigFakeDexIndicationRequest";
ConfigFakeDexIndicationComponent.init();

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "config-fake-dex-indication"
})
class ConfigFakeDexIndicationStore extends VuexModule {
  @Action
  public getList(params: ApiRequestList) {
    const presenter = container.resolve(ConfigFakeDexIndicationPresenter);
    return presenter.getList(params);
  }

  @Action
  public getDetail(id: any) {
    const presenter = container.resolve(ConfigFakeDexIndicationPresenter);
    return presenter.getDetail(id);
  }

  @Action
  public editConfig(params: {
    payload: EditConfigFakeDexIndicationRequest;
    id: any;
  }) {
    const presenter = container.resolve(ConfigFakeDexIndicationPresenter);
    return presenter.editConfig(params.payload, params.id);
  }
}

export const ConfigFakeDexIndicationController = getModule(
  ConfigFakeDexIndicationStore
);
